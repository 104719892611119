import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ServSingle from '../views/ServSingle.vue'
import Services from '../views/Services.vue'
import OurWorks from '../views/OurWorks.vue'
import Equipment from '../views/Equipment.vue'
import About from '../views/About.vue'
import Contacts from '../views/Contacts.vue'
import Dynamic from '../views/Dynamic.vue'
import Error from '../views/Error.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/404',
    component: Error
  },
  {
    path: '/services/:id',
    component: ServSingle,
    props: true
  },
  {
    path: '/services',
    component: Services
  },
  {
    path: '/our-works',
    component: OurWorks
  },
  {
    path: '/equipment',
    component: Equipment
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/contacts',
    component: Contacts
  },
  {
    path: '/:id',
    component: Dynamic,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
