<template>
    <div v-if="services.length">


        <section class="slide-box">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h1>{{single(id).title}}</h1>
                    </div>
                </div>
            </div>
        </section>


        <section id="prices">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <p class="black-txt" v-html="single(id).content">
                            
                        </p>

                        <div class="table-responsive">
                            <table class="table" style="margin-top:30px;" v-if="single(id).priceTable">
                            <tr>
                                <td v-for="(item, i) in single(id).priceTable.header" :key="i">
                                    {{item.c}}
                                </td>
                            </tr>
                            <tr v-for="(item, i) in single(id).priceTable.body" :key="i">
                                <td v-for="(tab, j) in item" :key="j">{{tab.c}}</td>
                            </tr>
                        </table>
                        </div>
                        

                    </div>
                </div>
            </div>
        </section>

        <Advant />
        <Form/>
        <Contacts />

    </div>
</template>

<script>
import Form from '../components/Form.vue'
import Advant from '../components/Advant.vue'
import Contacts from '../components/Contacts.vue'
import { mapGetters } from 'vuex'

export default {
    props: ["id"],
    components: {Advant, Form, Contacts},
    computed: {
        ...mapGetters({
            services: "content/getServices",
            single: "content/getSingle"
        })
    }
}
</script>

<style scoped>
.slide-box{
    padding: 150px 0;
}
h1{
    margin-bottom: 0;
}
</style>