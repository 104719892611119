<template>
    <section id="contacts">
        <div class="container">
            <div class="row mb-80">
                <div class="col-lg-12">
                    <h3>Дополнительная информация для клиентов</h3>
                    <p class="grey-txt">
                       {{dop}} 
                    </p>
                </div>
            </div>

            <div class="rows" v-if="contacts">
                <div class="col-lg-6 side1">
                    <h2>Контакты</h2>
                    <div class="cont-icon">
                        <img src="../assets/img/ci1.png" alt="">
                        <div>
                            <p class="grey-txt">{{contacts.vremya_raboty}}</p>
                            <p class="sub">{{contacts.adres}}</p>
                        </div>
                    </div>
                    <div class="cont-icon">
                        <img src="../assets/img/ci2.png" alt="">
                        <div>
                           <p class="grey-txt">{{contacts.vremya_raboty}}</p>
                            <a :href="`tel:${contacts.telefon}`"><p class="sub">{{contacts.telefon}}</p></a>
                        </div>
                    </div>
                    <div class="cont-icon">
                        <img src="../assets/img/ci3.png" alt="">
                        <div>
                            <p class="grey-txt">e-mail</p>
                           <a :href="`mailto:${contacts.email}`"><p class="sub">{{contacts.email}}</p></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 side2">
                    <yandex-map  :coords="coordinates" :scroll-zoom="false" :controls="[]"  :zoom="15" >
                        <ymap-marker 
                        :coords="coordinates" :icon="markerIcon" :marker-id="markerIcon.id" >
                        234</ymap-marker>
                    </yandex-map>
                </div>
            </div>  
        </div>
    </section>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            dop: 'content/getDop',
            contacts: "content/getContacts"
        })
    },
    watch: {
        contacts(){
            this.coordinates = JSON.parse(this.contacts.karta)
        }
    },
    data(){
        return{
            coordinates: [60.001329, 30.773937],
            markerIcon: {
                id:234,
				layout: 'default#imageWithContent',
			    imageHref: require('../assets/img/mpmark.svg'),
				imageSize: [55, 55],
			    imageOffset: [0, 0],
			},
        }
    }
}
</script>