<template>
    <header>
        <div class="container">
            <div class="shapka">
                <router-link tag="a" to="/">
                    <img src="https://smpro78.ru/wp-content/uploads/2022/05/logo.svg" class="logo">
                </router-link>

                <div class="search hidden-xs hidden-sm">
                    <!-- <input type="text" placeholder="Поиск по сайту"> -->
                    <cool-select
                        :items="items"
                        :placeholder="'Начните поиск'"
                        @search="onSearch"
                        :loading="loading"
                        :search-text.sync="searchInp"
                        disable-filtering-by-searc
                        item-text="title"
                        >
                        <!-- slot for each item in the menu -->
                        <template slot="item" slot-scope="{ item: link }">
                            <a :href="link.url">
                                 <div v-html="link.title"></div>
                            </a> 
                        </template>

                        <template slot="no-data" >
                        Нет результатов
                        </template>
                    </cool-select>
                </div>
                
                <div class="header-cont hidden-xs" v-if="contacts">
                    <h6>Адрес</h6>
                    <p style="max-width:250px;">{{contacts.adres}}</p>
                </div>
                 <div class="header-cont hidden-xs" v-if="contacts">
                    <h6>Контакты</h6>
                    <p><a :href="`tel:${contacts.telefon}`">{{contacts.telefon}}</a></p>
                    <p><a :href="`mailto:${contacts.email}`">{{contacts.email}}</a></p>
                </div>

                <button class="main-btn hidden-xs hidden-sm" @click="changePop">
                    Получить консультацию
                </button>

                <div class="burger hidden-lg hidden-md" @click="mobileMenu = !mobileMenu" :class="{activeBurger : mobileMenu}">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
        <div class="mobile-nav" :class="{mobileMenu : mobileMenu}" v-if="contacts">
            <ul>
                <router-link tag="li" :to="item.url" v-for="(item, index) in menu" :key="index">
                    {{item.name}}
                </router-link>
            </ul>
            <p><a :href="`tel:${contacts.telefon}`">{{contacts.telefon}}</a></p>
                    <p><a :href="`mailto:${contacts.email}`">{{contacts.email}}</a></p>
        </div>
    </header>
</template>

<script>
import axios from 'axios'
import { CoolSelect } from "vue-cool-select";
import { mapGetters } from 'vuex';

export default {
    components: {CoolSelect},
    computed: {
        ...mapGetters({
            menu: 'content/getMenu',
            contacts: "content/getContacts"
        })
    },
    methods:{
        changePop(){
            this.$store.dispatch("content/changePop")
        },
        async onSearch(search) {
            this.loading = true

            await axios.get(`https://smpro78.ru/wp-json/wp/v2/search?_embed&search=${search}`)
            .then(res =>{
                this.items = res.data
                this.loading = false
            })

            
        },
    },
    data(){
        return{
            loading: false,
            searchInp: '',
            items: [],
            mobileMenu: false,
            links: [
                {
                        txt: 'Услуги',
                        link: '/services'
                    },
                    {
                        txt: 'Наши работы',
                        link: '/our-works'
                    },
                    {
                        txt: 'Перечень оборудования',
                        link: '/equipment'
                    },
                    {
                        txt: 'О компании',
                        link: '/about'
                    },
                    {
                        txt: 'Контакты',
                        link: '/contacts'
                    },
            ]
        }
    }
}
</script>