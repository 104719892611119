<template>
  <div id="app">
    <transition name="slide" mode="out-in">
    <div class="preloader" v-if="preload">
      <img src="https://smpro78.ru/wp-content/uploads/2022/05/logo.svg" alt="">
    </div>
    </transition>
    <Header />
    <Nav />
    <Popup/>
    
    <router-view></router-view>

    <Footer/>

  </div>
</template>

<script>
import './assets/css/style.css'
import Header from './components/Header.vue'
import Nav from './components/Nav.vue'
import Footer from './components/Footer.vue'
import Popup from './components/Popup.vue'

export default {  
    components: {Header, Nav, Footer, Popup},
    async created(){
      await this.$store.dispatch("content/loadMenu")
      await this.$store.dispatch("content/loadServ")
      await this.$store.dispatch("content/loadSlider")
      await this.$store.dispatch("content/loadAbout")
      await this.$store.dispatch("content/loadFooter")
      await this.$store.dispatch("content/loadAdd")
      await this.$store.dispatch("content/loadDop")
      await this.$store.dispatch("content/loadWorks")
      await this.$store.dispatch("content/loadAdvant")
      await this.$store.dispatch("content/loadContacts")
      await this.$store.dispatch("content/loadEq")
      setTimeout(()=>{
        this.preload = false
      }, 1000)
    },
    data(){
      return{
        preload: true
      }
    }
}
</script>


<style>


.slide-leave-active{
	transition: opacity .3s ease;
	opacity: 0;
	animation: slide-out .3s  ease-out forwards;
}
.slide-leave{
	opacity: 1;
	transform: translateX(0px);
}
.slide-enter-active{
	animation: slide-in .3s  ease-out forwards;
	opacity: 1;
}



@keyframes slide-out{
	0%{
		transform: translateY(0);
	}
	100%{
		transform: translateY(50px);
	}
}
@keyframes slide-in{
	0%{
		transform: translateY(50px);
		opacity: 0;
	}
	100%{
		transform: translateY(0px);
		opacity: 1;
	}
}
</style>