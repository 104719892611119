<template>
    <section id="main">
        <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="(slide, index) in slider" :key="index">
                <div class="slide-box" :style="{'background-image': 'url(' + slide.image + ')'}">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 wow fadeInUp">
                                <h1>
                                    {{slide.title}}
                                </h1>
                                <p class="sub" v-html="slide.content">
                                </p>
                                <router-link tag="button" to="/services" class="main-btn">
                                    Услуги компании
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <!-- <div class="prevBtn newsPrev newsPrev2"></div>
        <div class="nextBtn newsNext newsNext2"></div> -->
        <div class="swiper-pagination"></div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            slider: "content/getSlider"
        })
    },
    data(){
        return{
            swiperOptions: {
                slidesPerView: 1,
                spaceBetween: 0,
                draggable: true,
                parallax: true,
                touchRatio: 1,
                loop: true,
                infinite: true,
                trеhreshold: 50,
                speed: 600,
                navigation: {
                    prevEl: '.newsPrev2',
                    nextEl: '.newsNext2'
                },
                pagination: {
					el: ".swiper-pagination",
                    clickable: true
				},
            },
        }
    }
}
</script>