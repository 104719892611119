<template>

    <footer v-if="footer.length && contacts">
        <div class="container">
            <div class="shapka">
                <ul>
                    <router-link tag="li" :to="item.url" v-for="(item, index) in footer" :key="index" >
                        {{item.name}}
                    </router-link>
                </ul>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <p class="grey-txt">© {{contacts.copyright}}</p>
                </div>
                <div class="col-lg-6 text-right">
                    <p class="grey-txt">
                        <a href="https://webink.site/" target="_blank">Разработано в студии - Webink.</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            footer: 'content/getFooter',
            contacts: "content/getContacts"
        })
    },
    data(){
        return{
            // links: [
            //     {
            //             txt: 'Услуги',
            //             link: '/services'
            //         },
            //         {
            //             txt: 'Наши работы',
            //             link: '/our-works'
            //         },
            //         {
            //             txt: 'Перечень оборудования',
            //             link: '/equipment'
            //         },
            //         {
            //             txt: 'О компании',
            //             link: '/about'
            //         },
            //         {
            //             txt: 'Контакты',
            //             link: '/contacts'
            //         },
            // ]
        }
    }
}
</script>

<style scoped>
.grey-txt a{
    color: #8D909B;
    transition: all .3s ease;
}
.grey-txt a:hover{
    color:#156FF0;
}
</style>