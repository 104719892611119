<template>
    <section id="serv">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Услуги</h2>
                </div>
                <!-- <pre>{{services}}</pre> -->
                <router-link tag="div" :to="`${item.url}`" class="col-lg-6" v-for="(item, i) in services" :key="i">
                    <div class="serv-box wow fadeInUp">
                        <img :src="item.img" alt="">
                        <h3>{{item.title}}</h3>
                        <p class="black-txt" v-if="item.fromPrice">
                            {{item.fromPrice}}
                        </p>
                        <button class="plus-btn"></button>
                    </div>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            services: 'content/getServices'
        })
    },
    data(){
        return{
        }
    }
}
</script>