<template>
  <div> 
      <Main />
      <Serv />
      <Advant />
      <Submit />
      <Works />
      <Contacts />
  </div>
</template>

<script>
import Main from '../components/Main.vue'
import Serv from '../components/Serv.vue'
import Advant from '../components/Advant.vue'
import Submit from '../components/Submit.vue'
import Works from '../components/Works.vue'
import Contacts from '../components/Contacts.vue'

export default {
  components: {Main, Serv, Advant, Submit, Works, Contacts}
}
</script>
