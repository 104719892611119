<template>
    <div v-if="dynamic">
        <section class="slide-box">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10">
                        <h1>{{dynamic.title}}</h1>
                    </div>
                </div>
            </div>
        </section>

        <section id="dynamic">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div v-html="dynamic.content"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <transition name="slide" mode="out-in" v-else>
    <div class="preloader">
      <img src="https://smpro78.ru/wp-content/uploads/2022/05/logo.svg" alt="">
    </div>
    </transition>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
    async created(){
        await this.$store.dispatch("content/loadPage", this.id)
    },
    props: ["id"],
    computed: {
        ...mapGetters({
            dynamic: "content/getDynamic"
        })
    },
    watch: {
        id(){
            this.$store.dispatch("content/loadPage", this.id)
        }
    }
}
</script>
