<template>
    <nav class="hidden-xs">
        <div class="container">
            <div class="shapka">
                <ul>
                    <router-link tag="li" :to="item.url" v-for="(item, index) in menu" :key="index" :class="{dropLi : item.children}">
                        {{item.name}}
                        <!-- <img src="https://upload.wikimedia.org/wikipedia/commons/9/9d/Arrow-down.svg" 
                        v-if="item.children"> -->
                        <!-- <ul class="drop" v-if="item.children">
                            <router-link tag="li" :to="child.link" v-for="(child, i) in item.children" :key="i">
                                {{child.txt}}
                            </router-link>
                        </ul> -->
                    </router-link>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import {mapGetters} from 'vuex'

    export default {
        computed: {
            ...mapGetters({
                menu: 'content/getMenu'
            })
        },
        data() {
            return {
                 links: [
                    // {
                //         txt: 'Газификация',
                //         link: '/gazification',
                //         target: false,
                //         children: [
                //             {
                //                 txt: 'Юр. лица',
                //                 link: '/urlica',
                //             },
                //             {
                //                 txt: 'Физ. лица',
                //                 link: '/fizlica',
                //             },
                //             {
                //                 txt: 'Поселки',
                //                 link: '/poselki',
                //             },
                //         ]
                //     },
                    {
                        txt: 'Услуги',
                        link: '/services'
                    },
                    {
                        txt: 'Наши работы',
                        link: '/our-works'
                    },
                    {
                        txt: 'Перечень оборудования',
                        link: '/equipment'
                    },
                    {
                        txt: 'О компании',
                        link: '/about'
                    },
                    {
                        txt: 'Контакты',
                        link: '/contacts'
                    },
                ]
            }
        }
    } 
</script>