<template>
    <section id="works">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Наши работы</h2>
                </div>
            </div>
            <div class="row" v-if="works.length">
                <div class="col-lg-12">
                    <swiper ref="mySwiper2" :options="swiperOptions">
                        <swiper-slide v-for="(slide, index) in works" :key="index">
                            <div class="work-box" :style="{'background-image': 'url(' + slide + ')'}"></div>
                        </swiper-slide>

                    </swiper>
                </div>
            </div>
        </div>
        <div class="pagination2"></div>        
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            works: "content/getWorks"
        })
    },
    data(){
        return {
            // slides: [
            //     require('../assets/img/im1.png'),
            //     require('../assets/img/im2.png'),
            //     require('../assets/img/im3.png')
            // ],
            swiperOptions: {
                slidesPerView: 3,
                spaceBetween: 30,
                draggable: true,
                parallax: true,
                touchRatio: 1,
                loop: true,
                infinite: true,
                speed: 600,
                navigation: {
                    prevEl: '.newsPrev2',
                    nextEl: '.newsNext2'
                },
                pagination: {
					el: ".pagination2",
                    clickable: true
				},
                breakpoints: {
			            320: {
			              slidesPerView: 1.2,
			              spaceBetween: 10,
                          freeMode: true,
                          threshold: 10,
			              touchRatio: 1,
			            },
			            768: {
				          slidesPerView: 2,
				           touchRatio: 1,
				        },
				        1150: {
				          slidesPerView: 3,
				          spaceBetween: 30,
				           touchRatio: 1,
				        }
			        }
            },
        }
    }
}
</script>