<template>
    <div>
        <section class="slide-box">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10">
                        <h1>О компании</h1>
                        <p class="sub">{{about.podzagolovok}}</p>
                    </div>
                </div>
            </div>
        </section>


        <section id="serv">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <p class="black-txt" style="font-weight:700;">
                            {{about.bloki_pod_zagolovkom.blok_sleva}}
                        </p>
                    </div>
                    <div class="col-lg-6">
                        <p class="black-txt">
                            {{about.bloki_pod_zagolovkom.blok_sprava}}
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <Advant />

        <section id="serv" style="padding-top:0;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <p class="black-txt" v-html="about.content"></p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Advant from '../components/Advant.vue'
export default {
    components:{Advant},
    computed: {
        ...mapGetters({
            about: "content/getAbout"
        })
    }
}
</script>

<style scoped>
.slide-box{
    background-image: url('../assets/img/txtre.jpg');
    padding: 150px 0;
}
h1{
    /* margin-bottom: 0; */
}
</style>