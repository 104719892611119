<template>
    <div>
        <Serv />
    </div>
</template>

<script>
import Serv from '../components/Serv.vue'

export default {
    components: {Serv}
}
</script>