<template>
    <div>
        <section id="serv">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h2>Контакты</h2>
                    </div>
                    <div class="col-lg-4">
                        <div class="serv-box wow fadeInUp">
                            <p class="grey-txt">Телефон</p>
                            <a :href="`tel:${contacts.telefon}`"><h3>{{contacts.telefon}}</h3></a>
                            <a :href="`tel:${contacts.telefon}#1100`"><h4>Добавочный: {{contacts.dobavochnyj}}</h4></a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="serv-box wow fadeInUp">
                            <p class="grey-txt">E-mail</p>
                            <a :href="`mailto:${contacts.email}`"><h3>{{contacts.email}}</h3>  </a>    
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="serv-box wow fadeInUp">
                            <p class="grey-txt">Адрес</p>
                            <h3>{{contacts.adres}}</h3>        
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Contacts />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Contacts from '../components/Contacts.vue'

export default {
    components: {Contacts},
    computed: {
        ...mapGetters({
            contacts: "content/getContacts"
        })
    },
}
</script>

<style scoped>
h3{
    max-width: 100%;
}
</style>>
