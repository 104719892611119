<template>
    <section id="advant">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2>Почему выбирают нас</h2>
                </div>
                <div class="col-lg-4 wow fadeInUp" v-for="(item, i) in advant" :key="i">
                    <div class="icon-box">
                        <div class="icon">
                            <img :src="item.ikonka" alt="">
                        </div>
                        <div class="icon-body">
                            <h4>{{item.zagolovok}}</h4>
                            <p class="black-txt">{{item.tekst}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            advant: "content/getAdvant"
        })
    },
    data(){
        return{
            
        }
    }
}
</script>

