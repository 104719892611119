import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import YmapPlugin from 'vue-yandex-maps'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueTheMask from 'vue-the-mask'
import Vuelidate from 'vuelidate'
import { CoolSelectPlugin } from 'vue-cool-select'


Vue.config.productionTip = false
import 'swiper/css/swiper.css'
import 'vue-cool-select/dist/themes/material-design.css'

const settings = {
  apiKey: '8c4059db-3b8d-4535-a15e-569ee80fc827',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1'
}
Vue.use(YmapPlugin, settings)
Vue.use(CoolSelectPlugin)
Vue.use(VueAwesomeSwiper)
Vue.use(VueTheMask)
Vue.use(Vuelidate)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
