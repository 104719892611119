<template>
    <div class="pop" v-if="visible" @click="changePop">
        <div @click.stop>
            <Form @closePop="changePop"/>
        </div>
    </div>
</template>

<script>
import Form from '../components/Form.vue'
import {mapGetters} from 'vuex'

export default {
    computed: {
        ...mapGetters({
            visible: "content/getPopVisible"
        })
    },
    methods: {
        changePop(){
            this.$store.dispatch('content/changePop')
        }
    },
    components: {Form}
}
</script>