import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import content from '../store/content.js'

export default new Vuex.Store({
	modules: {
		content
	}
}) 
