<template>
    <section id="submit">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="submit-box" v-if="dopblock">
                        <h2>{{dopblock.zagolovok}}</h2>
                        <div class="sub">{{dopblock.tekst}}</div>
                        <button class="main-btn" @click="changePop">Начать сотрудничество</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            dopblock: "content/getDopblock"
        })
    },
    methods: {
        changePop(){
            this.$store.dispatch("content/changePop")
        }
    }
}
</script>